


















































































































































































import { BalanceTzkt, TrCallback, TrooperzBoxParams, ConsType, ConsumablesParams, ConsumableInfo, MutableAttributes } from '@/type';
import { Component, Prop, Vue, PropSync, VModel } from 'vue-property-decorator';
import TrooperzData from '@/mixins/TrooperzData';
import WalletMixin from '@/mixins/WalletMixin';
import HasXp from '@/mixins/HasXp';
import { mixins } from 'vue-class-component';
import { getTokenBalanceOfUser } from '@/apiCaller/tzktApi';
import { useConsumables } from '@/contractCaller/contractCaller';
import { getInAttributes, getLvlWithXp, getReqForLvl } from '@/utils/utilities';
import OperationLoadingDialog from '../OperationLoadingDialog.vue'
import StatsTrooperzBox, { CustomStyles, SideText } from '../Common/StatsTrooperzBox.vue'
import { getEnergyInfoBase, getEnergyBase } from '@/utils/rewardsHelper';
import BuyBtn from '../BuyBtn.vue';
import HasWarning from '@/mixins/HasWarning';
import BaseDialog from '../Common/BaseDialog.vue';
import HasNotification from '@/mixins/HasNotification';


  @Component({
      components: {
        OperationLoadingDialog,
        StatsTrooperzBox,
        BuyBtn,
        BaseDialog
      }
  })
  export default class Consumables extends mixins(WalletMixin, TrooperzData, HasXp, HasWarning, HasNotification) {
    currentAddress: string | null = ''

    searchTable: null | string = null

    stylesCharcudoc = {
        background: 'url(' + require('@/assets/consumables/CharcuDoc.png') + ')'
    }
    // start at 0 to trigger the alert info 'select trooperz'
    // currentTrooperz is set to null when changing selected Trooperz to trigger the loading metadata
    currentTrooperz?: TrooperzBoxParams | null | 0 = 0

    refCurrentTrooperz = 'cons-curr-ref'
    idCurrentTrooperz = 'cons-curr-id'

    loadingIpfs = null

    selectedConsumables: ConsumableInfo[] = []

    consumableList: BalanceTzkt[] = []

    loadingUseCons = false

    opeHash?: string | null = null
    activeDialogTransaction = false
    success = false

    typeWhoNeedHarvest: ConsType[] = ['energy_add', 'energy_boost', 'xp_boost'] 

    contentNeedHarvest = `
    In order to allow the use of an energy_add, energy_boost or xp_boost, a harvest will be included in the transaction. 
    On your side, nothing to do, this is done automatically.
    `

    maxTrooperzStats = 9

    maxLvl = 50

    searchTrooperz = ''

    async mounted () {
        await this.initConsumables()
    }

    async initConsumables ()  {
        await this.initPage()
        if (this.$route.query.tokenId) {
            const result = this.data.find(item => item.token_id === this.$route.query.tokenId)
            if (result) {
                this.currentTrooperz = result
            }
        }
        await this.getConsumables()
    }

    async getConsumables () {
        if (this.$config && this.currentAddress) {
            const result = await getTokenBalanceOfUser(this.currentAddress, 
                                                                this.$config?.networkTzkt,   
                                                                this.$config?.contractBooster)
            this.consumableList = result.data
        }
    }

    getNeedHarvest (tokenId: number) {
        return !!(this.getCurrentMode(tokenId) && !!this.selectedConsumables.find(item =>  this.typeWhoNeedHarvest.includes(item.type)))
    }

    async askUseConsumables () {
        if (this.currentTrooperz) {
            const tokenId = Number(this.currentTrooperz.token_id)
            if (this.getNeedHarvest(tokenId)) {
                this.openWarning(this.contentNeedHarvest, this.useConsumables.bind(this), null, 'Ok, I understand', 'Cancel')
            } else {
                this.useConsumables()
            }
        } else {
            console.warn('No current Trooperz.')
        }
    }

    async useConsumables () {
        if (this.currentTrooperz && this.$config && this.$tezos.wallet && this.currentAddress) {
            this.loadingUseCons = true
            this.success = false
            this.activeDialogTransaction = true
            let error = false
            const tokenId = Number(this.currentTrooperz.token_id)
            const consumablesArray: ConsumablesParams[] = this.selectedConsumables.map(item => {
                return {
                    consumable_id: item.tokenId,
                    trooperz_id: tokenId
                }
            })
            const useConsContract = this.$config.contractUseConsumables
            const boosterContract = this.$config.contractBooster
            const needHarvest = this.getNeedHarvest(tokenId)
            const trainingContrat = this.$config.trainingRoomAddress
            try {
                const batch = await useConsumables(this.$tezos.wallet, this.currentAddress, 
                    consumablesArray, useConsContract, boosterContract, needHarvest, trainingContrat)
                this.opeHash = batch.opHash
                const result = await batch.confirmation();
            } catch (e) {
                console.warn(e)
                error = true
                this.activeDialogTransaction = false
            }
            this.success = !error
            this.loadingUseCons = false
        }

    }

    onCloseOperationDialogClose () {
        this.success = false
        this.opeHash = null
        this.selectedConsumables = []
        this.initConsumables()
    }

    removeXpAdd (amount: number) {
        const xpAdds = this.getSelectedConsumableType('xp_add', amount)
        if (!xpAdds.length) {
            return
        }
        this.removeConsumable(xpAdds[0])
    }

    removeConsumableTokenId (tokenId: number) {
        const constInfo = this.selectedConsumables.find(item => item.tokenId === tokenId)
        if (!constInfo) {
            console.warn('Can not find cons info!')
            return
        }
        this.removeConsumable(constInfo)
    }

    removeConsumable (consInfo: ConsumableInfo) {
        const balanceItem: BalanceTzkt | undefined = this.consumableList.find(item => Number(item.token.tokenId) === consInfo.tokenId)

        if (!balanceItem) {
            console.warn('Can not find balance item.')
            return
        }
        this.balanceAdd(balanceItem)
        const index = this.selectedConsumables.indexOf(consInfo)
        this.selectedConsumables.splice(index, 1)
    }

    balanceAdd (item: BalanceTzkt) {
        item.balance = String(Number(item.balance) + 1)
    }

    balanceMin (item: BalanceTzkt) {
        item.balance = String(Number(item.balance) - 1)
    }

    addConsumable (item: BalanceTzkt) {
        if (item && item.balance !== '0' && item.token.metadata && item.token.metadata.attributes) {
            const type = getInAttributes('type', item.token.metadata.attributes)
            if (type === 'energy_add' && this.totalEnergyToAdd) {
                const message = 'Can not add more than 1 Energy-Plus.'
                this.openNotification(message)
                return
            }

            if (type === 'xp_add' && this.getLevel(this.futurXpCurrentTrooperz) >= 50) {
                const message = 'Level 50 is the max level, this Trooperz don\'t need more xp!.'
                this.openNotification(message)
                return
            }

            if (type === 'stats_add' && this.statsBox 
            && this.statsBox[getInAttributes('stat', item.token.metadata.attributes) as keyof MutableAttributes] >= this.maxTrooperzStats) {
                const stats = getInAttributes('stat', item.token.metadata.attributes)
                const message = `${this.maxTrooperzStats} is the maximum a Trooperz can have when taking a ${stats} Booster!.`
                this.openNotification(message)
                return
            }
            this.selectedConsumables.push({
                type: getInAttributes('type', item.token.metadata.attributes),
                amount: Number(getInAttributes('amount', item.token.metadata.attributes)),
                tokenId: Number(item.token.tokenId),
                stats: getInAttributes('stat', item.token.metadata.attributes)
            })
            this.balanceMin(item)
        }
    }

    isCurrentTrooperz (boxParams: TrooperzBoxParams) {
        return this.currentTrooperz && this.currentTrooperz.token_id === boxParams.token_id
    }   

    setCurrentTrooperz (boxParams: TrooperzBoxParams | null) {
        // for triggering the loading ipfs
        this.currentTrooperz = null
        this.$nextTick(() => {
            this.manageIpfsLoad()
            this.currentTrooperz = boxParams
            if (this.currentTrooperz && this.currentTrooperz.token_id) {
                this.$router.replace({name: 'consumables', query: { tokenId: String(this.currentTrooperz.token_id)}})
            }
        })
    }

    amountForToken (tokenId: number) {
        let total = 0
        for (let cons of this.selectedConsumables) {
            if (cons.tokenId === tokenId) {
                total ++
            }
        }
        return total
    }

    manageIpfsLoad () {
        let trImg: any = document.getElementById(this.idCurrentTrooperz)
        this.startLoadingIpfs()
        this.startLoadingIpfs()

        if (trImg) {
            trImg.addEventListener('load', this.stopLoadingIpfs.bind(this))
            trImg.addEventListener('error', this.manageErrorIpfs.bind(this))
        }

    }

    manageErrorIpfs () {
        let img:(HTMLElement & {src: string}) | null = document.getElementById(this.idCurrentTrooperz) as (HTMLElement & {src: string}) | null
        if (img) {
            (this.loadingIpfs as any).changeText('Try reloading ipfs...')
            let source = img.src
            img.src = source
        }
    }

    startLoadingIpfs () {
        if (this.loadingIpfs) {
            return
        }
        this.loadingIpfs = this.$vs.loading({
            target: this.$refs[this.refCurrentTrooperz],
            text: 'Get ipfs data...'
        })
    }

    stopLoadingIpfs () {
        if (this.loadingIpfs) {
            (this.loadingIpfs as any).close()
            this.loadingIpfs = null
        }
    }

    getSelectedConsumableType (type: ConsType, amount: number) {
        return this.selectedConsumables.filter(item => {
            return item.type === type && item.amount === amount
        })
    }

    getEnergy (toAdd = true) {
        let val = null
        if ((this.rewards || this.energyCost ) && this.currentMode) {
            val = getEnergyBase(this.energyCurrentTrooperz, this.rewards, this.energyCost, this.currentMode, this.maxEnergy)
        } else {
            val = this.energyCurrentTrooperz ? this.energyCurrentTrooperz : 0
        }
        return Math.min(val +  (toAdd ? this.totalEnergyToAdd : 0), this.maxEnergy)
    }

    getEnergyInfo () {        
        if (this.totalEnergyToAdd) {
            return `+${this.totalEnergyToAdd}`
        }
        return null
    }

    getCurrentMode (tokenId: string | number) {
        if (this.trooperzModes[Number(tokenId)]) {
            return this.trooperzModes[Number(tokenId)].mode
        }
        return null
    }

    getModeInfo (tokenId: string | number) {
        const currentMode = this.getCurrentMode(tokenId)
        return currentMode ? currentMode : 'Not actif'
    }


    getTrooperzLink (tokenId: string | number) {
        return this.$router.resolve({ name: 'Trooperz', params: { tokenId: tokenId.toString() } }).href
    }

    getStatsToAdd (stats: keyof MutableAttributes) {
        let total = 0
        for (let cons of this.selectedConsumables) {
            if (cons.type === 'stats_add' && cons.stats === stats) {
                total ++
            }
        }
        return total
    }

    get rewards () {
        if (!this.currentTrooperz) {
            return 0
        }
        const currentMode = this.getCurrentMode(Number(this.currentTrooperz.token_id))
        const rewardInfo = this.rewardsInfos[Number(this.currentTrooperz.token_id)]
        return currentMode && rewardInfo ? rewardInfo.rewards: 0
    }

    get energyCost () {
        if (!this.currentTrooperz) {
            return 0
        }
        const currentMode = this.getCurrentMode(Number(this.currentTrooperz.token_id))
        const rewardInfo = this.rewardsInfos[Number(this.currentTrooperz.token_id)]
        return currentMode && rewardInfo ? rewardInfo.energyCost: 0
    }

    get currentMode () {
        return this.currentTrooperz && this.getCurrentMode(Number(this.currentTrooperz.token_id)) ? this.trooperzModes[Number(this.currentTrooperz.token_id)].mode : null
    }

    get xpCurrentTrooperz () {
        return this.currentTrooperz ? this.trooperzXp[Number(this.currentTrooperz.token_id)]: 0
    }

    get energyCurrentTrooperz () {
        return this.currentTrooperz ? this.trooperzEnergy[Number(this.currentTrooperz.token_id)]: 0
    }

    get maxEnergy () {
        return 5000000
    }


    get futurXpCurrentTrooperz () {
        return this.currentTrooperz ? Math.min(this.xpCurrentTrooperz + this.totalXpToAdd, this.maxXp) : 0
    }

    get xpToAddAmount() {
        let total = 0
        for (let cons of this.selectedConsumables) {
            if (cons.type === 'xp_add') {
                total ++
            }
        }
        return total
    }


    get filteredTrooperz () {
        return this.data.filter(item => {
            return item.metadata && item.metadata.name && item.metadata.name !== 'Trooperz To Reveal'
        }).filter(item => {
            return !this.searchTrooperz.trim() || 
            (item.metadata && item.metadata.name.toLocaleLowerCase().includes(this.searchTrooperz.trim().toLowerCase()))
        })
    }

    get totalXpToAdd () {
        let total = 0
        for (let cons of this.selectedConsumables) {
            if (cons.type === 'xp_add' && cons.amount) {
                total += cons.amount
            }
        }
        return total
    }

    get totalEnergyToAdd () {
        let total = 0
        for (let cons of this.selectedConsumables) {
            if (cons.type === 'energy_add' && cons.amount) {
                total += cons.amount
            }
        }
        return Math.min(total, this.maxEnergy)
    }

    get disabledUseConsumables () {
        return !this.selectedConsumables.length || !this.currentTrooperz
    }

    get diffLvl () {
        const prevLvl = getLvlWithXp(this.xpCurrentTrooperz)
        const newLvl = Math.min(getLvlWithXp(this.futurXpCurrentTrooperz), this.maxLvl)
        return newLvl - prevLvl
    }

    get maxXp () {
        return getReqForLvl(this.maxLvl)
    }

    get diffXp () {
        return this.futurXpCurrentTrooperz - this.xpCurrentTrooperz
    }
    

    get diffEnergy () {
        const prevEnergy = this.getEnergy(false)
        const newEnergy = Math.min(this.getEnergy(), this.maxEnergy)
        return newEnergy - prevEnergy
    }

    get diffHacking () {
        return this.getStatsToAdd('hacking')
    }

    get diffTrickery () {
        return this.getStatsToAdd('trickery')
    }

    get diffClosecombat () {
        return this.getStatsToAdd('close_combat')
    }

    get diffFirearms () {
        return this.getStatsToAdd('fire_arms')
    }

    get customStyles (): CustomStyles {
        const customStyles: CustomStyles = {}
        const colorPlus = 'aqua'

        if (this.totalEnergyToAdd) {
            customStyles.energy = {
                color: colorPlus
            }
        }

        if (this.totalXpToAdd) {
            customStyles.xp = {
                color: colorPlus
            }
        }

        if (this.diffLvl) {
            customStyles.lvl = {
                color: colorPlus
            }
        }

        if (this.diffClosecombat) {
            customStyles.close_combat = {
                color: colorPlus
            }
        }


        if (this.diffFirearms) {
            customStyles.fire_arms = {
                color: colorPlus
            }
        }


        if (this.diffHacking) {
            customStyles.hacking = {
                color: colorPlus
            }
        }

        if (this.diffTrickery) {
            customStyles.trickery = {
                color: colorPlus
            }
        }
        return customStyles
    }

    getTextPlusText (type: 'xp_add' | 'energy_add') {
        const info: Record<string, number> = {}

        this.selectedConsumables.forEach(item => {
            if (item.type === type && item.amount) {
                const key = item.amount
                info[key] = info[key] ? info[key] + 1 : 1
            }
        })

        let text = ''

        Object.keys(info).forEach(key => {
            text += `${key}x${info[key]} `
        })

        return text ? text : 0
    }


    getSideTextStats (stats: keyof MutableAttributes) {
        const info: Record<string, number> = {}

        this.selectedConsumables.forEach(item => {
            if (item.type === 'stats_add' && item.stats === stats) {
                const key = 1
                info[key] = info[key] ? info[key] + 1 : 1
            }
        })

        let text = ''

        Object.keys(info).forEach(key => {
            text += `${key}x${info[key]} `
        })

        return text ? text : 0
    }



    get sideText (): SideText {
        const xpInfo: Record<string, number> = {}

        this.selectedConsumables.forEach(item => {
            if (item.type === 'xp_add' && item.amount) {
                const key = item.amount
                xpInfo[key] = xpInfo[key] ? xpInfo[key] + 1 : 1
            }
        })

        let xpText = ''

        Object.keys(xpInfo).forEach(key => {
            xpText += `${key}x${xpInfo[key]} `
        })

        const sideText: SideText = {
            lvl: [0, `+${this.diffLvl}`],
            xp: [this.getTextPlusText('xp_add'), `+${this.diffXp}`],
            energy: [this.getTextPlusText('energy_add'), `+${this.diffEnergy}`],
            hacking: [this.getSideTextStats('hacking'), `+${this.diffHacking}`],
            fire_arms: [this.getSideTextStats('fire_arms'), `+${this.diffFirearms}`],
            trickery: [this.getSideTextStats('trickery'), `+${this.diffTrickery}`],
            close_combat: [this.getSideTextStats('close_combat'), `+${this.diffClosecombat}`]
        }
        return sideText
    }

    get buyConsumablesPath () {
        if (this.$config) {
            return { name: 'Marketplace' , query: {contractSearch: this.$config.contractBooster}}
        }
        return { name: 'Marketplace'}
    }

    get statsBox (): MutableAttributes | null {
        if (this.currentTrooperz && this.currentTrooperz.upgradablesAttributes) {
            return {
                fire_arms: this.currentTrooperz.upgradablesAttributes.fire_arms + this.diffFirearms,
                hacking: this.currentTrooperz.upgradablesAttributes.hacking + this.diffHacking,
                trickery: this.currentTrooperz.upgradablesAttributes.trickery + this.diffTrickery,
                close_combat: this.currentTrooperz.upgradablesAttributes.close_combat + this.diffClosecombat
            }
        }
        return null

    }
  }
  